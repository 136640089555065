import { ReactElement } from "react";

import { Box, DataRow, Divider, makeStyles, Route, Typography } from "@portex-pro/ui-components";
import capitalize from "lodash/capitalize";
import compact from "lodash/compact";
import first from "lodash/first";
import isNumber from "lodash/isNumber";
import last from "lodash/last";
import orderBy from "lodash/orderBy";
import toLower from "lodash/toLower";
import { useTranslation } from "react-i18next";
import { isTemperatureControlled } from "types/TrailerType";
import VerticalStopsInfo from "views/stops/VerticalStopsInfo";

import { PublicQuoteRequest } from "../../../api/types/generated-types";
import LoadTypeChip from "../../../components/LoadTypeChip";
import TrailerTypeIcon from "../../../components/TrailerTypeIcon";
import { formatUSD } from "../../../utils/formatCurrency";
import { formatNumber } from "../../../utils/formatNumber";
import { formatTrailerType } from "../../../utils/formatTrailerType";
import { formatWeight } from "../../../utils/formatUnit";
import { renderSerializedNotes } from "../../../utils/renderSerializedNotes";
import { getFtlTrailerSize } from "../utils/getFtlTrailerSize";
import { mapDriverPreferenceToCopy } from "../utils/mapDriverPreferenceToCopy";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiDataRow-root": {
      "&.MuiDataRow-root > *": {
        marginRight: "3rem",
      },
    },
  },
  review: {
    letterSpacing: "0.8px",
    textTransform: "uppercase",
  },
}));

type QuoteSubmissionDetailsProps = {
  isBrokerPreview?: boolean;
  publicQuoteRequest: PublicQuoteRequest;
};

const QuoteSubmissionDetailsFTL = ({
  isBrokerPreview,
  publicQuoteRequest,
}: QuoteSubmissionDetailsProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const classes = useStyles();

  const {
    commodities,
    hazardous_goods_details,
    is_hazardous,
    item_packaging,
    item_type,
    packing_count,
    pallet_count,
    pallet_type,
    locations,
    max_temp,
    min_temp,
    mode,
    notes,
    portex_id,
    reference_number,
    temp_unit,
    total_weight,
    trailer_size,
    trailer_type,
    weight_unit,
    truck_quantity,
    driver_preference,
    goods_value,
  } = publicQuoteRequest;

  const getTrailerInfo = () => {
    const maybeTrailerSize = getFtlTrailerSize(trailer_size);
    const trailerTypeParsed = formatTrailerType(trailer_type);

    const maybeMinTemp = isNumber(min_temp) ? `${min_temp}˚${temp_unit}` : "";
    const maybeMinMaxTemp = isNumber(max_temp) ? `${max_temp}˚${temp_unit}` : "";

    const maybeTemps =
      isTemperatureControlled(trailer_type) && (min_temp || max_temp)
        ? `(${[maybeMinTemp, maybeMinMaxTemp].filter(Boolean).join(" to ")})`
        : "";

    return [maybeTrailerSize, trailerTypeParsed, maybeTemps].filter(Boolean).join(" ");
  };

  const orderedLocations = orderBy(locations, "position");

  const pickupLocationShortName = first(orderedLocations)?.formatted_short_name;
  const deliveryLocationShortName = last(orderedLocations)?.formatted_short_name;

  const maybeWeight = total_weight && weight_unit ? formatWeight(total_weight, toLower(weight_unit)) : "";
  const maybeCommodities = commodities ? commodities.split(",").join(", ") : "";

  return (
    <Box className={classes.root}>
      {!isBrokerPreview ? (
        <Box my={3}>
          <Typography variant={"h5"} align={"center"}>
            <strong>{t("common:quoteRequest")}</strong>
          </Typography>
          {pickupLocationShortName && deliveryLocationShortName ? (
            <Box py={4}>
              <Route pickup={pickupLocationShortName} delivery={deliveryLocationShortName} />
            </Box>
          ) : null}
        </Box>
      ) : null}

      <Box display={"flex"} alignItems={"baseline"} justifyContent={"space-between"} px={3.5} py={2}>
        <Typography className={classes.review}>
          <strong>{t("common:quoteDetails")}</strong>
        </Typography>
        <Typography>{portex_id ? `Portex ID # ${portex_id}` : ""}</Typography>
      </Box>

      <Divider />

      {reference_number ? (
        <>
          <DataRow label={t("common:referenceNo")}>
            <Box>
              <Typography display={"inline"}>{reference_number}</Typography>
            </Box>
          </DataRow>
          <Divider />
        </>
      ) : null}

      <DataRow label={t("common:mode")}>
        <Box>
          <LoadTypeChip mode={mode} boxProps={{ display: "inline" }} />
          &nbsp; &nbsp;
          <Typography display={"inline"}>
            <strong>{t("shipper:fullTruckload")}</strong>
          </Typography>
        </Box>
      </DataRow>

      <Divider />

      <DataRow label={t("shipper:equipment")}>
        <Box display="flex" alignItems="center">
          <TrailerTypeIcon type={trailer_type} small />
          <Typography display={"inline"} style={{ marginLeft: "1rem" }}>
            <strong>{getTrailerInfo()}</strong>
          </Typography>
        </Box>
      </DataRow>

      <Divider />

      <DataRow label={t("shipper:numOfTrucks")}>
        <Box display="flex" alignItems="center">
          <Typography display={"inline"} style={{ marginLeft: "1rem" }}>
            <strong>{truck_quantity}</strong>
          </Typography>
        </Box>
      </DataRow>

      <Divider />

      <DataRow label={t("shipper:locations")} labelAlignStart>
        <VerticalStopsInfo stops={compact(orderedLocations)} />
      </DataRow>

      <Divider />

      <DataRow label={t("shipper:packingMethod")}>
        <Typography>
          <strong>{item_packaging}</strong>
        </Typography>
      </DataRow>

      <Divider />

      {driver_preference && (
        <>
          <DataRow label={t("shipper:driverPreference")}>
            <Typography>
              <strong>{mapDriverPreferenceToCopy[driver_preference]}</strong>
            </Typography>
          </DataRow>
          <Divider />
        </>
      )}

      {item_packaging === "Floor Loaded" ? (
        <>
          {packing_count ? (
            <>
              <DataRow label={t("shipper:packingCount")}>
                <Typography>
                  <strong>{packing_count}</strong>
                </Typography>
              </DataRow>
              <Divider />
            </>
          ) : null}

          {item_type ? (
            <>
              <DataRow label={t("shipper:packingType")}>
                <Typography>
                  <strong>{capitalize(item_type)}</strong>
                </Typography>
              </DataRow>
              <Divider />
            </>
          ) : null}
        </>
      ) : item_packaging === "Palletized" ? (
        <>
          {pallet_count ? (
            <>
              <DataRow label={t("shipper:palletCount")}>
                <Typography>
                  <strong>{formatNumber(pallet_count)}</strong>
                </Typography>
              </DataRow>
              <Divider />
            </>
          ) : null}
          {pallet_type ? (
            <>
              <DataRow label={t("shipper:palletType")}>
                <Typography>
                  <strong>{t(`shipper:palletTypeMap.${pallet_type}`)}</strong>
                </Typography>
              </DataRow>
              <Divider />
            </>
          ) : null}
        </>
      ) : null}

      {maybeWeight ? (
        <>
          <DataRow label={t("common:totalWeight")}>
            <Typography>
              <strong>{maybeWeight}</strong>
            </Typography>
          </DataRow>
          <Divider />
        </>
      ) : null}

      {maybeCommodities || is_hazardous ? (
        <>
          {maybeCommodities ? (
            <DataRow label={t("common:commodities")}>
              <Typography>
                <strong>{maybeCommodities}</strong>
              </Typography>
            </DataRow>
          ) : null}
          {is_hazardous ? (
            <>
              <DataRow label={maybeCommodities ? "" : t("common:commodities")}>
                <Typography color="error">
                  <strong>{t("common:containsHazardous")}</strong>
                </Typography>
              </DataRow>
              {hazardous_goods_details ? (
                <DataRow label={""}>
                  <Typography>
                    {t("shipper:notesOnHazardous")} <br />
                    {hazardous_goods_details}
                  </Typography>
                </DataRow>
              ) : null}
              <Divider />
            </>
          ) : (
            <Divider />
          )}
        </>
      ) : null}

      {notes ? (
        <>
          <DataRow label={t("common:additionalNotes")}>
            <Typography>{renderSerializedNotes(notes)}</Typography>
          </DataRow>
          <Divider />
        </>
      ) : null}

      {goods_value ? (
        <>
          <DataRow label={t("common:goodsValue")}>
            <Typography>{goods_value ? formatUSD(goods_value) : ""}</Typography>
          </DataRow>
          <Divider />
        </>
      ) : null}
    </Box>
  );
};

export default QuoteSubmissionDetailsFTL;
