import { ComponentProps, VFC } from "react";

import { getShipmentSourcesApi } from "api/rest/shipments";
import { useGetShipmentDetailsQuery, useUpdateShipmentMutation } from "api/rest/shipments/shipmentsApi";
import { ShipmentState } from "app/pages/shipments/types/domain";
import { useSnackbar } from "notistack";
import { StepsFTL } from "pages/shipper/pages/request-quote/pages/ftl/types/StepsFTL";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import CancelShipmentDialogView from "./CancelShipmentDialogView";

const CancelShipmentDialogContainer: VFC<{
  shipmentId: number | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (() => Promise<unknown>) | (() => unknown);
}> = ({ shipmentId, isOpen, onClose, onSuccess }) => {
  const { t } = useTranslation(["common", "shipments"]);
  const shipmentsQuery = useGetShipmentDetailsQuery(
    { urlParams: { shipmentId: Number(shipmentId) } },
    { skip: !shipmentId }
  );

  const [updateShipment, updateShipmentMutation] = useUpdateShipmentMutation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCancelShipment: ComponentProps<typeof CancelShipmentDialogView>["onSubmit"] = async (
    cancellationType,
    reason: string
  ) => {
    const mode = shipmentsQuery.data?.mode;
    const isRecreate = cancellationType === "cancel-recreate" || cancellationType === "cancel-rebook";
    try {
      const result = await updateShipment({
        urlParams: { shipmentId: Number(shipmentId) },
        body: {
          state: ShipmentState.Canceled,
          canceledReason: reason,
          versionNumber: shipmentsQuery.data?.versionNumber,
          recreate: isRecreate
            ? {
                rebook: cancellationType === "cancel-rebook",
              }
            : undefined,
        },
      }).unwrap();
      await onSuccess?.();
      onClose();

      dispatch(getShipmentSourcesApi.util.invalidateTags(["SOURCES"]));

      if (cancellationType === "cancel-recreate") {
        if (mode === "FTL" && result.recreate?.quoteRequestId) {
          history.push(
            `/shipper/request-quote/FTL/${StepsFTL.Locations}?fromTemplate=0&quoteRequestId=${result.recreate.quoteRequestId}`
          );
        } else if (mode === "LTL") {
          history.push(`/shipper/request-quote/LTL?recreate=1`);
        }
      } else if (cancellationType === "cancel-rebook") {
        history.push(`/shipper/quotes/${result.recreate?.quoteRequestId}`);
      }
    } catch (e) {
      enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
    }
  };

  if (!shipmentsQuery.data?.id) {
    return null;
  }
  return (
    <CancelShipmentDialogView
      isOpen={isOpen}
      onClose={onClose}
      loading={updateShipmentMutation.isLoading}
      onSubmit={handleCancelShipment}
      showRecreatePrompt={
        shipmentsQuery.data?.sourceType === "quote" &&
        (shipmentsQuery.data?.mode === "FTL" || shipmentsQuery.data?.mode === "LTL")
      }
    />
  );
};

export default CancelShipmentDialogContainer;
