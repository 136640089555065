import { ReactElement, useState } from "react";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Box, Button, ListItemText, Menu, MenuItem, Tooltip } from "@portex-pro/ui-components";
import DuplicateQuoteRequest from "app/pages/request-management/containers/quotes/DuplicateQuoteRequest";
import { useSetIsDuplicatingQuoteRequest } from "app/pages/request-management/store/quoteRequestManagementSlice";
import {
  useRequestManagementSliceSelector,
  useRequestManagementSlices,
} from "app/pages/request-management/store/requestManagementStore";
import CancelShipmentDialogContainer from "features/shipments/CancelShipmentDialogContainer";
import useLDFlag from "hooks/useLDFlag";
import { useTranslation } from "react-i18next";

import { QuoteRequest, QuoteRequestState, Mode } from "../../../api/types/generated-types";
import useCreateTemplateDialog from "../hooks/useCreateTemplateDialog";
import { useGetQuoteRequestShipment } from "../hooks/useQuoteRequestShipment";
import CancelQuoteDialog from "./CancelQuoteDialog";
import CloseQuoteDialog from "./CloseQuoteDialog";
import CreateTemplateDialogView from "./CreateTemplateDialogView";
import EditDetailsDialog from "./EditDetailsDialog";
import ResendQuoteRequestDialog from "./ResendQuoteRequestDialog";
import ViewQuoteRequestDialog from "./ViewQuoteRequestDialog";

type QuoteRequestHeaderActionsProps = {
  quoteRequest: QuoteRequest;
  refetchQuoteRequest?: () => Promise<unknown>;
};

const QuoteRequestHeaderActions = ({
  quoteRequest,
  refetchQuoteRequest,
}: QuoteRequestHeaderActionsProps): ReactElement => {
  useRequestManagementSlices();
  const { t } = useTranslation(["common", "shipper"]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [viewQuoteRequestDialogOpen, setViewQuoteRequestDialogOpen] = useState(false);
  const [resendQuoteRequestDialogOpen, setResendQuoteRequestDialogOpen] = useState(false);
  const [closeQuoteDialogOpen, setCloseQuoteDialogOpen] = useState(false);
  const [editDetailsDialogOpen, setEditDetailsDialogOpen] = useState(false);
  const [cancelQuoteDialogOpen, setCancelQuoteDialogOpen] = useState(false);
  const [cancelShipmentDialogOpen, setCancelShipmentDialogOpen] = useState(false);
  const { isDuplicatingQuoteRequest } = useRequestManagementSliceSelector((state) => state.quoteRequestManagementSlice);
  const setIsDuplicatingQuoteRequest = useSetIsDuplicatingQuoteRequest();

  const enableCreateTemplate = useLDFlag("templatesForExistingQRs");
  const createTemplateDialog = useCreateTemplateDialog({ quoteRequestId: quoteRequest.id });

  const shipmentsQuery = useGetQuoteRequestShipment({ portexId: quoteRequest?.portex_id });
  const shipment = shipmentsQuery.data?.data?.[0];

  const canResendQuoteRequest =
    quoteRequest.state === QuoteRequestState.Requested ||
    quoteRequest.state === QuoteRequestState.Sent ||
    quoteRequest.state === QuoteRequestState.Returned;

  const canEditDetails =
    quoteRequest.state === QuoteRequestState.Sent ||
    quoteRequest.state === QuoteRequestState.Requested ||
    quoteRequest.state === QuoteRequestState.Returned;

  const canCloseQuoteRequest =
    quoteRequest.state === QuoteRequestState.Sent ||
    quoteRequest.state === QuoteRequestState.Requested ||
    quoteRequest.state === QuoteRequestState.Returned;

  const canCancelQuoteRequest = quoteRequest.state === QuoteRequestState.Booked;

  const resendQuoteRequestDisabledTooltip = ((): string => {
    switch (quoteRequest.state) {
      case QuoteRequestState.Returned: {
        return t("shipper:disabledTooltip.resend.returned");
      }
      case QuoteRequestState.Booked: {
        return t("shipper:disabledTooltip.resend.booked");
      }
      case QuoteRequestState.Closed: {
        return t("shipper:disabledTooltip.resend.closed");
      }
      case QuoteRequestState.Canceled: {
        return t("shipper:disabledTooltip.resend.canceled");
      }
      default: {
        return "";
      }
    }
  })();

  const editDetailsDisabledTooltip = ((): string => {
    switch (quoteRequest.state) {
      case QuoteRequestState.Booked: {
        return t("shipper:disabledTooltip.editDetails.booked");
      }
      case QuoteRequestState.Canceled: {
        return t("shipper:disabledTooltip.editDetails.canceled");
      }
      case QuoteRequestState.Closed: {
        return t("shipper:disabledTooltip.editDetails.closed");
      }
      default: {
        return "";
      }
    }
  })();

  const closeQuoteRequestDisabledTooltip = ((): string => {
    switch (quoteRequest.state) {
      case QuoteRequestState.Booked: {
        return t("shipper:disabledTooltip.close.booked");
      }
      case QuoteRequestState.Closed: {
        return t("shipper:disabledTooltip.close.closed");
      }
      default: {
        return "";
      }
    }
  })();

  const cancelQuoteRequestDisabledTooltip = ((): string => {
    switch (quoteRequest.state) {
      case QuoteRequestState.Canceled: {
        return t("shipper:disabledTooltip.cancel.canceled");
      }
      default: {
        return t("shipper:disabledTooltip.cancel.default");
      }
    }
  })();

  return (
    <>
      <Button
        style={{ paddingLeft: "24px", paddingTop: 2, paddingBottom: 2, borderWidth: 2 }}
        color="primary"
        variant="outlined"
        endIcon={<MoreVertIcon style={{ marginLeft: -10, fontSize: "30px" }} />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        size="medium"
      >
        {t("shipper:actions")}
      </Button>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: 0,
        }}
        MenuListProps={{ disablePadding: true }}
        PaperProps={{ elevation: 10, style: { border: "none", margin: "0" } }}
      >
        <MenuItem
          disabled={!quoteRequest}
          onClick={() => {
            setAnchorEl(null);
            setViewQuoteRequestDialogOpen(true);
          }}
        >
          <ListItemText primary={t("shipper:viewQuoteRequest")} />
        </MenuItem>

        <MenuItem
          disabled={!enableCreateTemplate}
          onClick={() => {
            setAnchorEl(null);
            createTemplateDialog.dialogOpen.setTrue();
          }}
        >
          <ListItemText primary={t("shipper:createTemplateDialog.saveAsTemplate")} />
        </MenuItem>

        <Tooltip
          title={cancelQuoteRequestDisabledTooltip}
          placement="left"
          arrow
          PopperProps={{
            popperOptions: {
              modifiers: {
                offset: {
                  enabled: true,
                  offset: "-50px 0px",
                },
              },
            },
          }}
          disableHoverListener={canCancelQuoteRequest}
        >
          <Box>
            <MenuItem
              disabled={!canCancelQuoteRequest}
              onClick={() => {
                setAnchorEl(null);
                if (!!shipment?.id) {
                  setCancelShipmentDialogOpen(true);
                } else {
                  setCancelQuoteDialogOpen(true);
                }
              }}
            >
              <ListItemText primary={t("shipper:cancelShipment")} />
            </MenuItem>
          </Box>
        </Tooltip>

        <div>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setIsDuplicatingQuoteRequest(true);
            }}
          >
            <ListItemText primary={t("shipper:duplicateQuoteRequest")} />
          </MenuItem>
          <DuplicateQuoteRequest.Drawer
            isOpen={isDuplicatingQuoteRequest}
            onClose={() => setIsDuplicatingQuoteRequest(false)}
          />
        </div>

        <Tooltip
          title={resendQuoteRequestDisabledTooltip}
          placement="left"
          arrow
          PopperProps={{
            popperOptions: {
              modifiers: {
                offset: {
                  enabled: true,
                  offset: "-50px 0px",
                },
              },
            },
          }}
          disableHoverListener={canResendQuoteRequest}
        >
          <Box>
            <MenuItem
              disabled={!canResendQuoteRequest}
              onClick={() => {
                setAnchorEl(null);
                setResendQuoteRequestDialogOpen(true);
              }}
            >
              <ListItemText primary={t("shipper:resendQuoteRequest")} />
            </MenuItem>
          </Box>
        </Tooltip>

        {quoteRequest.mode === Mode.Ftl ? (
          <Tooltip
            title={editDetailsDisabledTooltip}
            placement="left"
            arrow
            PopperProps={{
              popperOptions: {
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: "-50px 0px",
                  },
                },
              },
            }}
            disableHoverListener={canEditDetails}
          >
            <Box>
              <MenuItem
                disabled={!canEditDetails}
                onClick={() => {
                  setAnchorEl(null);
                  setEditDetailsDialogOpen(true);
                }}
              >
                <ListItemText primary={t("shipper:editDetails")} />
              </MenuItem>
            </Box>
          </Tooltip>
        ) : null}

        <Tooltip
          title={closeQuoteRequestDisabledTooltip}
          placement="left"
          arrow
          PopperProps={{
            popperOptions: {
              modifiers: {
                offset: {
                  enabled: true,
                  offset: "-50px 0px",
                },
              },
            },
          }}
          disableHoverListener={canCloseQuoteRequest}
        >
          <Box>
            <MenuItem
              disabled={!canCloseQuoteRequest}
              onClick={() => {
                setAnchorEl(null);
                setCloseQuoteDialogOpen(true);
              }}
            >
              <ListItemText primary={t("shipper:closeQuote")} />
            </MenuItem>
          </Box>
        </Tooltip>
      </Menu>

      <EditDetailsDialog
        quoteRequest={quoteRequest}
        refetchQuoteRequest={refetchQuoteRequest}
        editDetailsDialogOpen={editDetailsDialogOpen}
        setEditDetailsDialogOpen={setEditDetailsDialogOpen}
      />

      <CloseQuoteDialog
        quoteRequest={quoteRequest}
        refetchQuoteRequest={refetchQuoteRequest}
        closeQuoteDialogOpen={closeQuoteDialogOpen}
        setCloseQuoteDialogOpen={setCloseQuoteDialogOpen}
      />

      <ViewQuoteRequestDialog
        quoteRequest={quoteRequest}
        viewQuoteRequestDialogOpen={viewQuoteRequestDialogOpen}
        setViewQuoteRequestDialogOpen={setViewQuoteRequestDialogOpen}
      />

      <ResendQuoteRequestDialog
        quoteRequest={quoteRequest}
        refetchQuoteRequest={refetchQuoteRequest}
        resendQuoteRequestDialogOpen={resendQuoteRequestDialogOpen}
        setResendQuoteRequestDialogOpen={setResendQuoteRequestDialogOpen}
      />

      <CancelQuoteDialog
        quoteRequest={quoteRequest}
        refetchQuoteRequest={refetchQuoteRequest}
        isOpen={cancelQuoteDialogOpen}
        onClose={() => setCancelQuoteDialogOpen(false)}
      />
      <CancelShipmentDialogContainer
        shipmentId={shipment?.id}
        isOpen={cancelShipmentDialogOpen}
        onClose={() => setCancelShipmentDialogOpen(false)}
        onSuccess={refetchQuoteRequest}
      />

      <CreateTemplateDialogView
        isOpen={createTemplateDialog.dialogOpen.value}
        onClose={createTemplateDialog.dialogOpen.setFalse}
        onSubmit={createTemplateDialog.onSubmit}
        loading={createTemplateDialog.loading}
      />
    </>
  );
};

export default QuoteRequestHeaderActions;
