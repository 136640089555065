import { ReactElement } from "react";

import { Box } from "@material-ui/core";
import TrailerTypeIcon from "components/TrailerTypeIcon";
import compact from "lodash/compact";
import isNumber from "lodash/isNumber";
import uniq from "lodash/uniq";
import { getFtlTrailerSize } from "pages/shipper/utils/getFtlTrailerSize";
import { ModeRfp } from "types/Mode";
import { TrailerSize } from "types/TrailerSize";
import { isTemperatureControlled, TrailerType } from "types/TrailerType";
import { formatTrailerType } from "utils/formatTrailerType";

const ContractTrailerTypeWithIconView = <
  T extends {
    trailer_size: TrailerSize | null;
    trailer_type: TrailerType;
    min_temp?: number | null;
    max_temp?: number | null;
    mode: ModeRfp;
  }
>({
  contract,
}: {
  contract: T;
}): ReactElement => {
  let trailerInfo = `${getFtlTrailerSize(contract.trailer_size)} ${formatTrailerType(contract.trailer_type)}`;

  if (isTemperatureControlled(contract.trailer_type)) {
    const minTempString = isNumber(contract.min_temp) ? `${contract.min_temp}˚ F` : "";
    const maxTempString = isNumber(contract.max_temp) ? `${contract.max_temp}˚ F` : "";
    const temps = compact(uniq([minTempString, maxTempString])).join(" to ");

    if (temps) {
      trailerInfo = `${trailerInfo} (${temps})`;
    }
  }

  return (
    <Box display="flex" alignItems="center">
      {contract.mode === "FTL" && <TrailerTypeIcon small type={contract.trailer_type} />}
      <Box pl={1}>{`${trailerInfo}`}</Box>
    </Box>
  );
};

export default ContractTrailerTypeWithIconView;
