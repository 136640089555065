import { FC } from "react";

import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import { AppBar, Box, portexColor, Toolbar } from "@portex-pro/ui-components";
import shipmentsApi from "api/rest/shipments/shipmentsApi";
import useLDFlag from "hooks/useLDFlag";
import LtlQuoteRequestHeader from "pages/shipper/components/LtlQuoteRequestHeader";
import ShipperAppHeader from "pages/shipper/components/ShipperAppHeader";
import { useGetQuoteRequestShipment } from "pages/shipper/hooks/useQuoteRequestShipment";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Mode,
  Mutation,
  MutationBookQuoteArgs,
  MutationUpdateStopDetailsArgs,
  Query,
  QueryGetQuoteRequestArgs,
} from "../../../../api/types/generated-types";
import NotFound404 from "../../../../components/errors/NotFound404";
import NotReachable500 from "../../../../components/errors/NotReachable500";
import Loading from "../../../../components/Loading";
import { useOnApolloError } from "../../../../hooks/useOnApolloError";
import AirQuoteRequestHeader from "../../components/AirQuoteRequestHeader";
import FclQuoteRequestHeader from "../../components/FclQuoteRequestHeader";
import QuoteRequestHeader from "../../components/QuoteRequestHeader";
import AirReviewBookingBody from "./components/AirReviewBookingBody";
import FclReviewBookingBody from "./components/FclReviewBookingBody";
import FtlReviewBookingBody from "./components/FtlReviewBookingBody";
import LtlReviewBookingBody from "./components/LtlReviewBookingBody";

const Address = gql`
  fragment ReviewBooking_Address on Address {
    id
    address_1
    address_2
    city
    country_code
    country_name
    formatted_long_name
    formatted_short_name
    google_place_id
    google_place_description
    iana_timezone
    lat
    lon
    name
    port_name
    phone_number
    province_code
    province_name
    hours_start
    hours_end
    zip
    address_contacts {
      id
      first_name
      last_name
      phone_number
      email
    }
    type
    airport_iata_code
    airport_name
    booking_notes
  }
`;

const STOP = gql`
  fragment ReviewBooking_Stop on Stop {
    id
    position
    note
    start
    end
    note
    booking_notes
    is_time_tbd
    reference_number
    distance_from_prior_stop
    address {
      ...ReviewBooking_Address
    }
  }
  ${Address}
`;

const QUOTES = gql`
  fragment ReviewBooking_Quote on Quote {
    id
    company_name
    conversation_id
    notes
    booking_notes
    status
    total_amount
    rate_per_load
    valid_until
    submitter_tz
    submitter_email
    created_at
  }
`;

const LTL_QUOTES = gql`
  fragment ReviewBooking_LtlQuote on LtlQuote {
    id
    company_name
    conversation_id
    notes
    booking_notes
    carrier_name
    status
    total_amount
    valid_until
    submitter_tz
    submitter_email
    created_at
    min_transit_time
    max_transit_time
  }
`;

const FCL_QUOTES = gql`
  fragment ReviewBooking_FclQuote on FclQuote {
    id
    submitter_email
    total_amount
    valid_until
    submitter_tz
    status
    company_name
    conversation_id
    notes
    notes_charges_insurance
    notes_charges_miscellaneous
    booking_notes
    benchmark
    min_transit_time
    max_transit_time
    quote_charges {
      id
      name
      type
      quantity
      unit
      rate
    }
    quote_charges_totals {
      origin
      freight
      destination
      customs
    }
    origin_port {
      ...ReviewBooking_Address
    }
    via_port {
      ...ReviewBooking_Address
    }
    destination_port {
      ...ReviewBooking_Address
    }
    destination_rail_ramp {
      ...ReviewBooking_Address
    }
    inland_mode_of_transport
    created_at
    carrier
  }
  ${Address}
`;

const AIR_QUOTES = gql`
  fragment ReviewBooking_AirQuote on AirQuote {
    id
    submitter_email
    total_amount
    valid_until
    submitter_tz
    status
    company_name
    conversation_id
    notes
    notes_charges_insurance
    notes_charges_miscellaneous
    booking_notes
    min_transit_time
    max_transit_time
    quote_charges {
      id
      name
      type
      quantity
      unit
      rate
    }
    quote_charges_totals {
      origin
      freight
      destination
      customs
    }
    origin_airport {
      ...ReviewBooking_Address
    }
    via_airport {
      ...ReviewBooking_Address
    }
    destination_airport {
      ...ReviewBooking_Address
    }
    created_at
    service_level
    chargeable_weight
  }
  ${Address}
`;

const FTL_LOAD_SPEC = gql`
  fragment ReviewBooking_FtlLoadSpec on FtlLoadSpec {
    id
    trailer_type
    trailer_size
    truck_quantity
    is_palletized
    packaging_type
    packing_count
    pallet_count
    pallet_type
    total_weight
    weight_unit
    commodities
    min_temp
    max_temp
    temp_unit
    driver_preference
    trucks {
      id
      trailer_size
      trailer_type
      reference_number
    }
  }
`;

const LTL_LOAD_SPEC = gql`
  fragment ReviewBooking_LtlLoadSpec on LtlLoadSpec {
    id
    trailer_type
    weight
    weight_unit
    min_temp
    max_temp
    accessorials {
      id
      accessorial {
        id
        name
        type
      }
    }
    package_groups {
      id
      commodities
      hazardous_goods_details
      is_hazardous
      item_quantity
      packaging_type
      length_per_package
      width_per_package
      height_per_package
      dim_unit
      weight_per_package
      freight_class
      nmfc_code
      is_stackable
    }
    truck {
      id
      reference_number
      trailer_size
      trailer_type
    }
  }
`;

const QUOTE_REQUEST = gql`
  fragment ReviewBooking_QuoteRequest on QuoteRequest {
    id
    unloading_details
    booked_at
    closed_at
    canceled_at
    mode
    state
    reference_number
    deadline_respond_at
    note
    carrier_routing_pref_notes
    ftl_load_specs {
      ...ReviewBooking_FtlLoadSpec
    }
    ltl_load_spec {
      ...ReviewBooking_LtlLoadSpec
    }
    quotes {
      ...ReviewBooking_Quote
    }
    ltl_quotes {
      ...ReviewBooking_LtlQuote
    }
    fcl_quotes {
      ...ReviewBooking_FclQuote
    }
    air_quotes {
      ...ReviewBooking_AirQuote
    }
    stops {
      ...ReviewBooking_Stop
    }
    shipper {
      id
      name
    }
    goods_value
    ...QuoteRequestHeader_QuoteRequest
    ...FclQuoteRequestHeader_QuoteRequest
    ...AirQuoteRequestHeader_QuoteRequest
  }
  ${FTL_LOAD_SPEC}
  ${LTL_LOAD_SPEC}
  ${QUOTES}
  ${LTL_QUOTES}
  ${FCL_QUOTES}
  ${AIR_QUOTES}
  ${STOP}
  ${QuoteRequestHeader.fragments.QuoteRequest}
  ${FclQuoteRequestHeader.fragments.QuoteRequest}
  ${AirQuoteRequestHeader.fragments.QuoteRequest}
`;

const GET_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "getQuoteRequest">, QueryGetQuoteRequestArgs> = gql`
  query ($id: ID!) {
    getQuoteRequest(id: $id) {
      ...ReviewBooking_QuoteRequest
    }
  }
  ${QUOTE_REQUEST}
`;

export const BOOK_QUOTE: TypedDocumentNode<{ bookQuote: Mutation["bookQuote"] }, MutationBookQuoteArgs> = gql`
  mutation ($input: BookQuoteInput!) {
    bookQuote(input: $input)
  }
`;

export const UPDATE_STOP_DETAILS: TypedDocumentNode<
  { updateStopDetails: Mutation["updateStopDetails"] },
  MutationUpdateStopDetailsArgs
> = gql`
  mutation ($input: UpdateStopDetailsInput!) {
    updateStopDetails(input: $input) {
      ...ReviewBooking_Stop
    }
  }
  ${STOP}
`;

type Params = {
  quoteRequestId: string;
  quoteId: string;
};

const STICKY_HEADER_OFFSET = 52;

const QuoteDetailsPage: FC = () => {
  const shipmentsTab = useLDFlag("shipmentsTab");
  const { onApolloError } = useOnApolloError({ componentName: "QuoteDetailsPage" });
  const { quoteRequestId, quoteId } = useParams<Params>();
  const dispatch = useDispatch();

  const {
    data,
    error,
    loading: quoteRequestLoading,
    refetch,
  } = useQuery(GET_QUOTE_REQUEST, {
    variables: {
      id: quoteRequestId,
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getQuoteRequest"),
  });

  const quoteRequest = data?.getQuoteRequest;
  const { currentData, refetch: refetchShipment } = useGetQuoteRequestShipment({ portexId: quoteRequest?.portex_id });
  const shipment = currentData?.data?.[0];

  const handleQuoteBooked = () => {
    dispatch(shipmentsApi.util.invalidateTags(["shipment"]));

    if (shipmentsTab) {
      refetchShipment();
    }
    return refetch();
  };

  if (!quoteRequest && quoteRequestLoading) {
    return (
      <>
        <Box
          bgcolor={"background.paper"}
          style={{ display: "flex", alignItems: "center", height: `calc(100vh - ${STICKY_HEADER_OFFSET}px)` }}
        >
          <Loading height="auto" spinnerOnly />;
        </Box>
      </>
    );
  }

  if (error) {
    return <NotReachable500 />;
  }

  if (!quoteRequest) {
    return <NotFound404 />;
  }

  const renderHeader = () => {
    switch (quoteRequest.mode) {
      case Mode.Ftl:
        return <QuoteRequestHeader quoteRequest={quoteRequest} refetchQuoteRequest={refetch} />;
      case Mode.Ltl:
        return <LtlQuoteRequestHeader quoteRequest={quoteRequest} refetchQuoteRequest={refetch} />;
      case Mode.Fcl:
        return <FclQuoteRequestHeader quoteRequest={quoteRequest} refetchQuoteRequest={refetch} />;
      case Mode.Air:
        return <AirQuoteRequestHeader quoteRequest={quoteRequest} refetchQuoteRequest={refetch} />;
      default:
        return null;
    }
  };

  const renderBody = () => {
    switch (quoteRequest.mode) {
      case Mode.Ftl:
        return (
          <FtlReviewBookingBody
            quoteRequest={quoteRequest}
            quoteId={quoteId}
            onQuoteBooked={handleQuoteBooked}
            shipmentId={shipment?.id || null}
          />
        );
      case Mode.Ltl:
        return (
          <LtlReviewBookingBody
            quoteRequest={quoteRequest}
            quoteId={quoteId}
            onQuoteBooked={handleQuoteBooked}
            shipmentId={shipment?.id || null}
          />
        );
      case Mode.Fcl:
        return <FclReviewBookingBody quoteRequest={quoteRequest} quoteId={quoteId} refetchQuoteRequest={refetch} />;
      case Mode.Air:
        return <AirReviewBookingBody quoteRequest={quoteRequest} quoteId={quoteId} refetchQuoteRequest={refetch} />;
      default:
        return null;
    }
  };

  const toolbarBorderPx = 1;
  const toolbarHeightPx = 50 + toolbarBorderPx;

  return (
    <>
      <AppBar>
        <Toolbar
          variant="dense"
          disableGutters
          style={{ borderBottom: `${toolbarBorderPx}px solid ${portexColor.grey300}`, height: toolbarHeightPx }}
        >
          <ShipperAppHeader />
        </Toolbar>
      </AppBar>
      <Box>
        <AppBar style={{ top: toolbarHeightPx }}>{renderHeader()}</AppBar>
        {renderBody()}
      </Box>
    </>
  );
};

export default QuoteDetailsPage;
