import { ReactElement, useCallback, useState } from "react";

import { TypedDocumentNode, gql, useMutation } from "@apollo/client";
import CancelShipmentDialogView from "features/shipments/CancelShipmentDialogView";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  Mutation,
  MutationCancelQuoteRequestArgs,
  MutationCreateQuoteRequestFromExistingQuoteRequestArgs,
  QuoteRequest,
} from "../../../api/types/generated-types";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import { StepsFTL } from "../pages/request-quote/pages/ftl/types/StepsFTL";

export const CANCEL_QUOTE_REQUEST: TypedDocumentNode<
  { cancelQuoteRequest: Mutation["cancelQuoteRequest"] },
  MutationCancelQuoteRequestArgs
> = gql`
  mutation ($input: CancelQuoteRequestInput!) {
    cancelQuoteRequest(input: $input)
  }
`;

export const RECREATE_QUOTE_REQUEST: TypedDocumentNode<
  { createQuoteRequestFromExistingQuoteRequest: Mutation["createQuoteRequestFromExistingQuoteRequest"] },
  MutationCreateQuoteRequestFromExistingQuoteRequestArgs
> = gql`
  mutation ($input: CreateQuoteRequestFromExistingQuoteRequestInput!) {
    createQuoteRequestFromExistingQuoteRequest(input: $input) {
      id
      mode
    }
  }
`;

type CancelQuoteDialogProps = {
  quoteRequest: QuoteRequest;
  refetchQuoteRequest?: () => Promise<unknown>;
  isOpen: boolean;
  onClose: () => void;
};

/**
 * @deprecated
 * We are no longer using this cancel quote dialog. Use the newer shipments cancellation instead.
 * This is still used for customers that have the shipments feauture flag turned off, and for booked quotes such as FCL + AIR where shipments do not exist yet (as of 9/16/24)
 * @see `<CancelShipmentDialogContainer />`
 */
const CancelQuoteDialog = ({
  quoteRequest,
  refetchQuoteRequest,
  isOpen,
  onClose,
}: CancelQuoteDialogProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "CancelQuoteDialog" });
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [cancelQuoteRequest] = useMutation(CANCEL_QUOTE_REQUEST, { onError: onApolloError("cancelQuoteRequest") });
  const [recreateQuoteRequest] = useMutation(RECREATE_QUOTE_REQUEST, {
    onError: onApolloError("recreateQuoteRequest"),
  });

  const handleCancelQuoteRequest = useCallback(
    async (reason: string) => {
      if (!quoteRequest?.id) return;

      try {
        setLoading(true);
        const { data } = await cancelQuoteRequest({
          variables: {
            input: {
              id: quoteRequest.id,
              reason: reason,
            },
          },
        });

        if (data?.cancelQuoteRequest === true) await refetchQuoteRequest?.();
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
        onClose();
      }
    },
    [cancelQuoteRequest, onClose, quoteRequest.id, refetchQuoteRequest]
  );

  const handleRecreateQuoteRequest = useCallback(
    async (reason: string) => {
      if (!quoteRequest?.id) return;

      setLoading(true);

      const { data: cancelData } = await cancelQuoteRequest({
        variables: {
          input: {
            id: quoteRequest.id,
            reason: reason,
          },
        },
      });

      if (cancelData?.cancelQuoteRequest === true) {
        await refetchQuoteRequest?.();
      } else {
        enqueueSnackbar(t("shipper:cancelQuoteDialog.unableToCancel"));
        return;
      }

      const { data } = await recreateQuoteRequest({
        variables: {
          input: {
            quoteRequestId: quoteRequest.id,
          },
        },
      });
      setLoading(false);
      onClose();
      const mode = data?.createQuoteRequestFromExistingQuoteRequest?.mode;
      const quoteRequestId = data?.createQuoteRequestFromExistingQuoteRequest?.id;
      if (!mode || !quoteRequestId) {
        throw new Error(t("shipper:cancelQuoteDialog.unableToRecreate"));
      } else {
        history.push(
          `/shipper/request-quote/FTL/${StepsFTL.Locations}?fromTemplate=0&quoteRequestId=${quoteRequestId}`
        );
      }
    },
    [
      cancelQuoteRequest,
      enqueueSnackbar,
      onClose,
      history,
      quoteRequest.id,
      recreateQuoteRequest,
      refetchQuoteRequest,
      t,
    ]
  );

  return (
    <CancelShipmentDialogView
      isOpen={isOpen}
      loading={loading}
      onSubmit={(cancellationType, reason) => {
        if (cancellationType === "cancel-recreate") {
          handleRecreateQuoteRequest(reason);
        } else if (cancellationType === "cancel-only") {
          handleCancelQuoteRequest(reason);
        } else if (cancellationType === "cancel-rebook") {
          throw new Error("Not implemented");
        }
      }}
      showRecreatePrompt={quoteRequest.mode === "FTL"}
      hideRebookOption
      onClose={onClose}
    />
  );
};

export default CancelQuoteDialog;
