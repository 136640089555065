import { useGetShipmentListQuery } from "api/rest/shipments/shipmentsApi";
import useLDFlag from "hooks/useLDFlag";
import OptionalMaybe from "types/OptionalMaybe";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetQuoteRequestShipment = ({ portexId }: { portexId: OptionalMaybe<string> }) => {
  const shipmentsTab = useLDFlag("shipmentsTab");
  return useGetShipmentListQuery(
    {
      queryParams: { search: portexId || "should-skip-if-missing" },
    },
    { skip: !shipmentsTab || !portexId }
  );
};
