import { VFC } from "react";

import { Button, ButtonGroup } from "@portex-pro/ui-components";
import { Shipment } from "app/pages/shipments/types/domain";
import Text from "components/Text";
import { ShipmentPatchFunction } from "features/shipments/provider/ShipmentDetailsProvider";
import { useTranslation } from "react-i18next";
import { getSizesForType } from "types/TrailerSize";

interface TrailerSizeViewProps {
  patchedShipment: Shipment;
  onChange: ShipmentPatchFunction;
}

const TrailerSizeView: VFC<TrailerSizeViewProps> = ({ patchedShipment, onChange }: TrailerSizeViewProps) => {
  const { t } = useTranslation(["shipper", "common"]);

  return (
    <div>
      <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
        {t("shipper:loadAttributesStep.trailerSize")}
      </Text>
      <ButtonGroup color={patchedShipment.loadSpec.trailerSize ? "primary" : "secondary"} fullWidth>
        {getSizesForType(patchedShipment.loadSpec.trailerType).map((size) => (
          <Button
            key={size}
            className={patchedShipment.loadSpec.trailerSize === size ? "Ptx-selected" : ""}
            onClick={() => onChange({ loadSpec: { trailerSize: size } })}
          >
            {t(`common:trailerSizes.${size}`)}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default TrailerSizeView;
