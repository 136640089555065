import { VFC } from "react";

import { MenuItem, SelectInput } from "@portex-pro/ui-components";
import { Shipment } from "app/pages/shipments/types/domain";
import Text from "components/Text";
import { ShipmentPatchFunction } from "features/shipments/provider/ShipmentDetailsProvider";
import { useTranslation } from "react-i18next";
import { isValidSizeForType } from "types/TrailerSize";
import { TrailerType, TrailerTypeEnum } from "types/TrailerType";
import { formatTrailerType } from "utils/formatTrailerType";

interface TrailerTypeViewProps {
  patchedShipment: Shipment;
  onChange: ShipmentPatchFunction;
}

const trailerTypes = Object.values(TrailerTypeEnum);

const TrailerTypeView: VFC<TrailerTypeViewProps> = ({ patchedShipment, onChange }: TrailerTypeViewProps) => {
  const { t } = useTranslation("shipper");

  return (
    <div>
      <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
        {t("trailerType")}
      </Text>
      <SelectInput
        fullWidth
        value={patchedShipment.loadSpec.trailerType ?? undefined}
        onChange={(event) => {
          const value = event.target.value as TrailerType;
          onChange({
            loadSpec: {
              trailerType: value,
              trailerSize: isValidSizeForType(value, patchedShipment.loadSpec.trailerSize)
                ? patchedShipment.loadSpec.trailerSize
                : null,
            },
          });
        }}
        SelectProps={{ style: { minWidth: "250px" } }}
      >
        {trailerTypes.map((trailerType) => (
          <MenuItem id={trailerType} value={trailerType}>
            {formatTrailerType(trailerType)}
          </MenuItem>
        ))}
      </SelectInput>
    </div>
  );
};

export default TrailerTypeView;
