import { ShipperDispatchRequest } from "api/rest/dispatches";
import { LocationOptionTypeEnum } from "types/AddressOption";
import { ModeShipments } from "types/Mode";
import { TrailerSize } from "types/TrailerSize";
import { TrailerType } from "types/TrailerType";

export enum ShipmentState {
  Draft = "DRAFT",
  Created = "CREATED",
  InTransit = "IN_TRANSIT",
  Delivered = "DELIVERED",
  Canceled = "CANCELED",
}

export enum ShipmentMode {
  Drayage = "DRAYAGE",
  Ftl = "FTL",
  Ltl = "LTL",
  Fcl = "FCL",
  Lcl = "LCL",
  Air = "AIR",
  Intermodal = "INTERMODAL",
}

export enum ShipmentPalletType {
  Pallet_48_40 = "PALLET_48_40",
  Pallet_42_42 = "PALLET_42_42",
  Pallet_48_48 = "PALLET_48_48",
  Pallet_40_48 = "PALLET_40_48",
  Pallet_48_42 = "PALLET_48_42",
  Pallet_40_40 = "PALLET_40_40",
  Pallet_48_45 = "PALLET_48_45",
  Pallet_44_44 = "PALLET_44_44",
  Pallet_36_36 = "PALLET_36_36",
  Pallet_48_36 = "PALLET_48_36",
  Pallet_45_455 = "PALLET_45_455",
  Pallet_48_20 = "PALLET_48_20",
  PalletNotSpecified = "PALLET_NOT_SPECIFIED",
}

export enum ShipmentPackagingType {
  Pallets_48_48 = "PALLETS_48_48",
  Pallets_48_40 = "PALLETS_48_40",
  Cartons = "CARTONS",
  Pallets = "PALLETS",
  Bags = "BAGS",
  Bales = "BALES",
  Barrels = "BARRELS",
  Boxes = "BOXES",
  Bundles = "BUNDLES",
  Coils = "COILS",
  Containers = "CONTAINERS",
  Crates = "CRATES",
  Rolls = "ROLLS",
  Skids = "SKIDS",
  Totes = "TOTES",
  Other = "OTHER",
}

export enum DriverPreference {
  None = "NONE",
  Solo = "SOLO",
  Team = "TEAM",
}

export enum ShipmentAddressType {
  Airport = "AIRPORT",
  Seaport = "SEAPORT",
  Railhead = "RAILHEAD",
  Street = "STREET",
  Unknown = "UNKNOWN",
}

export enum ShipmentWeightUnit {
  Kg = "KG",
  Lb = "LB",
}

export interface ShipmentContact {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
}

export interface ShipmentAddress {
  id: number;
  address1?: string;
  address2?: string;
  city: string;
  contact?: ShipmentContact;
  countryCode: string;
  countryName: string;
  googlePlaceDescription?: string;
  googlePlaceId?: string;
  hoursEnd?: string | null;
  hoursStart?: string | null;
  ianaTimezone: string;
  isDirty?: boolean;
  name?: string;
  portName?: string;
  provinceCode?: string;
  provinceName?: string;
  type?: ShipmentAddressType;
  zip?: string;
  optionType?: LocationOptionTypeEnum;
  bookingNotes: string;
}

// Shipment list

export interface ShipmentTruck {
  id?: number;
  referenceNumber?: string | null;
  trackingLink?: string;
}

export enum ShipmentAccessorialType {
  Delivery = "DELIVERY",
  Pickup = "PICKUP",
}

export interface ShipmentAccessorial {
  id: number;
  name: string;
  type: ShipmentAccessorialType;
}

export enum ShipmentDimUnit {
  IN = "IN",
  CM = "CM",
}

export enum ShipmentFreightClass {
  FC_50 = "FC_50",
  FC_55 = "FC_55",
  FC_60 = "FC_60",
  FC_65 = "FC_65",
  FC_70 = "FC_70",
  FC_77_5 = "FC_77_5",
  FC_85 = "FC_85",
  FC_92_5 = "FC_92_5",
  FC_100 = "FC_100",
  FC_110 = "FC_110",
  FC_125 = "FC_125",
  FC_150 = "FC_150",
  FC_175 = "FC_175",
  FC_200 = "FC_200",
  FC_250 = "FC_250",
  FC_300 = "FC_300",
  FC_400 = "FC_400",
  FC_500 = "FC_500",
}

export interface ShipmentPackageGroup {
  id: string; // used only for React element key
  packagingType: ShipmentPackagingType;
  itemQuantity: number;
  isStackable?: boolean;
  weightPerPackage: number;
  lengthPerPackage: number;
  heightPerPackage: number;
  widthPerPackage: number;
  dimUnit?: ShipmentDimUnit;
  freightClass?: ShipmentFreightClass;
  nmfcCode?: string;
  commodities?: string;
}

export interface ShipmentLoadSpec {
  commodities?: string;
  driverPreference?: DriverPreference;
  isPalletized?: boolean;
  maxTemp?: number | null;
  minTemp?: number | null;
  packageGroups?: ShipmentPackageGroup[];
  packagingType?: ShipmentPackagingType;
  packingCount?: number;
  palletCount?: number;
  palletType?: ShipmentPalletType;
  totalWeight?: number;
  trailerSize?: TrailerSize | null;
  trailerType?: TrailerType;
  unitCount?: number;
  weightPerUnit?: number;
  weightUnit?: ShipmentWeightUnit;
}

/** @todo: rename this - it's generic quote info, not only for booked quotes */
export interface ShipmentBookedQuote {
  id: string;
  bookingNotes?: string;
  companyName: string;
  createdAt: string;
  notes?: string;
  ratePerLoad?: number;
  status: string;
  submitterEmail: string;
  totalAmount: number;
  carrier_name?: string;
}

export interface ShipmentQuoteDetails extends ShipmentBookedQuote {
  timestamp: number;
  transitTime: { min?: number; max?: number };
  ratePerLoad: number;
  quotesFromTheSameCompany: number;
  /** Ordinal number within quotes from the same company */
  quoteNumberWithinGroup: number;
}

export interface ShipmentStop {
  id: number;
  address?: ShipmentAddress;
  end?: string;
  /** This field is used specifically for "Email/Call to Schedule" */
  isTimeTbd: boolean;
  note?: string | null;
  bookingNotes: string;
  referenceNumber?: string | null;
  start?: string;
}

export interface ShipmentOwner {
  id: number;
  email: string;
  firstName?: string;
  lastName?: string;
}

type ShipmentQuoteSource = {
  sourceType: "quote";
  bookedQuote: ShipmentBookedQuote;
  quoteRequestId: number;
  quoteHistory: ShipmentBookedQuote[];
};

type ShipmentDispatchSource = {
  dispatchRequest: ShipperDispatchRequest;
  sourceType: "dispatch";
};

export type ShipmentSource = ShipmentQuoteSource | ShipmentDispatchSource;

export type Shipment = ShipmentSource & {
  id: number;
  accessorials?: ShipmentAccessorial[];
  additionalNotes?: string;
  bookingDate: string;
  canceledReason?: string;
  conversationId?: string;
  destination: ShipmentStop;
  fileIds: string[];
  goodsValue?: number;
  hazardousGoodsDetails?: string;
  isHazardous?: boolean;
  loadSpec: ShipmentLoadSpec;
  mode: ShipmentMode;
  origin: ShipmentStop;
  owner?: ShipmentOwner;
  portexId: string;
  referenceNumber: string;
  state: ShipmentState;
  stops: ShipmentStop[];
  trucks?: ShipmentTruck[];
  estimated_mileage?: number;
  adjusted_total?: number;
  versionNumber?: number;
  shipper: { name: string };
  partner: {
    email: string;
    company_name?: string | null;
  };
  lane: string;
  equipment: string;
  lastStatusUpdateAt: string | null;
  partner_contact_id: number;
  rating: number | null | undefined;
  rating_notes: string;
};

export type PublicShipment = Pick<
  Shipment,
  | "id"
  | "bookingDate"
  | "equipment"
  | "lane"
  | "partner"
  | "portexId"
  | "referenceNumber"
  | "shipper"
  | "sourceType"
  | "stops"
  | "trucks"
  | "lastStatusUpdateAt"
> & {
  _type: "PublicShipment";
  mode: ModeShipments;
};

export type BaseShipment = {
  id: number;
  portexId: string;
  referenceNumber: string;
  state: ShipmentState;
  mode: ShipmentMode;
  origin: ShipmentStop;
  destination: ShipmentStop;
  stops: ShipmentStop[];
  loadSpec: Pick<ShipmentLoadSpec, "minTemp" | "maxTemp" | "trailerSize" | "trailerType">;
  trucks?: ShipmentTruck[];
  partner: { companyName: string };
};

export type ShipmentId = Shipment["id"];

export type ShipmentTransitDetailType = "booking" | "pickup" | "delivery" | "stop" | "dispatch";

export interface SimpleShipmentTransitDetail {
  date: string;
  type: ShipmentTransitDetailType;
}

export interface ShipmentTransitDetailBooking extends SimpleShipmentTransitDetail {
  type: "booking";
  bookingEntity: string;
  bookingPrice: string;
}

export interface ShipmentTransitDetailPickup extends SimpleShipmentTransitDetail {
  type: "pickup";
  location: string;
  address: string;
}

export interface ShipmentTransitDetailDelivery extends SimpleShipmentTransitDetail {
  type: "delivery";
  location: string;
  address: string;
}

export interface ShipmentTransitDetailStop extends SimpleShipmentTransitDetail {
  type: "stop";
  stopNumber: number | undefined;
  location: string;
  address: string;
}

export interface ShipmentTransitDetailDispatch extends SimpleShipmentTransitDetail {
  type: "dispatch";
  finalAmount: string;
  bookingEntity: string;
}

export type ShipmentTransitDetail =
  | ShipmentTransitDetailBooking
  | ShipmentTransitDetailPickup
  | ShipmentTransitDetailDelivery
  | ShipmentTransitDetailStop
  | ShipmentTransitDetailDispatch;

export type ShipmentTabId = "activity" | "details" | "documents" | "scorecard" | "quotes" | "invoices" | "bol";

export type DateFormat = "long" | "short";
